import React from "react"
import { Box } from "./ui"
import { videoIframe, videoWrapper, videoOuterWrapper } from "./video.css"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <Box className={videoOuterWrapper}>
    {/* <Box className={videoWrapper}> */}
    <LiteYouTubeEmbed
      className={videoIframe}
      iframeClass={videoIframe}
      id={videoSrcURL}
      title={videoTitle}
      noCookie={true}
      // wrapperClass={videoWrapper}
      // webp={true}
    />
    {/* </Box> */}
  </Box>
)
export default Video
